import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { RouteAuthenticationRequirement } from '@/shared/types/generic';
import useTokenStore from '@/store/token/useTokenStore';
import useOrganizationStore from '@/store/organization/useOrganizationStore';

const TVDisplaysView = () =>
  import(
    /* webpackChunkName: "tv" */ '@/views/TVDisplaysView/TVDisplaysView.vue'
  );
const LoginView = () =>
  import(/* webpackChunkName: "tv" */ '@/views/LoginView/LoginView.vue');

const OrganizationDisplaysView = () =>
  import(
    /* webpackChunkName: "organization" */ '@/views/OrganizationDisplaysView/OrganizationDisplaysView.vue'
  );

const NotFoundView = () =>
  import(
    /* webpackChunkName: "not-found" */ '@/views/NotFoundView/NotFoundView.vue'
  );

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: '/',
  },
  {
    path: '/',
    name: 'main',
    component: LoginView,
    meta: {
      bodyClass: 'background-default',
    },
    beforeEnter: async (to, _from, next) => {
      const organizationStore = useOrganizationStore();
      const { orgName, displayCode } = to.query;

      if (orgName) {
        organizationStore.state.organizationName = orgName as string;
        await organizationStore.loadOrganizationDisplays(orgName as string);

        if (organizationStore.hasOnlyOneDisplay) {
          organizationStore.selectDisplay(
            organizationStore.state.organizationDisplays[0],
          );
          next(`/login`);
        } else {
          next('/list');
        }
      }
      if (displayCode) {
        const code = displayCode as string;
        await organizationStore.loadOrganizationDisplayByDisplayCode(
          parseInt(code, 10),
        );
        if (organizationStore.state.selectedDisplay) {
          await organizationStore.loadOrganizationDisplays(
            organizationStore.state.selectedDisplay?.orgName,
          );
        }
        next('/login');
      }
      next();
    },
  },
  {
    path: '/list',
    meta: {
      bodyClass: 'background-default',
      authenticationRequirement: RouteAuthenticationRequirement.UNAUTHENTICATED,
    },
    component: OrganizationDisplaysView,
  },
  {
    path: '/login',
    meta: { bodyClass: 'background-login' },
    component: LoginView,
  },
  {
    path: '/home',
    name: 'home',
    component: TVDisplaysView,
    meta: {
      bodyClass: 'background-default',
      authenticationRequirement: RouteAuthenticationRequirement.AUTHENTICATED,
    },
  },
  {
    path: '/404',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  const tokenStore = useTokenStore();

  const routeRequiresAuthentication = to.matched.some(
    (route) =>
      route.meta.authenticationRequirement ===
      RouteAuthenticationRequirement.AUTHENTICATED,
  );

  const isAuthenticated = !!tokenStore.settings.accessToken;

  if (
    routeRequiresAuthentication &&
    !isAuthenticated &&
    !tokenStore.settings.loginWithoutPin
  ) {
    next('/');
    return;
  }

  next();
});

export default router;
