import {
  ChallengeAudienceStaticReference,
  ChallengeGoalStaticReference,
  ChallengeLeaderboardActor,
  ChallengeModeStaticReference,
  ChallengeTypeStaticReference,
  NewChallenge,
  NewChallengeSlide,
} from '@/shared/types/challenges';
import { DatePeriod, StatusStaticReference } from '@/shared/types/generic';
import { getRandomNumber } from '@vaibe-github-enterprise/kd-kse-fe-commons';

export const mockActors = (): ChallengeLeaderboardActor[] => {
  return [
    {
      reference: '1',
      position: 1,
      username: 'User 1',
      urlAvatar:
        'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-01.png',
      units: 40,
    },
    {
      reference: '2',
      position: 2,
      username: 'User 2',
      urlAvatar:
        'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-02.png',
      units: 90,
    },
    {
      reference: '3',
      position: 3,
      username: 'User 3',
      urlAvatar:
        'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-03.png',
      units: 80,
    },
    {
      reference: '4',
      position: 4,
      username: 'User 4',
      urlAvatar:
        'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-04.png',
      units: 70,
    },
    {
      reference: '5',
      position: 5,
      username: 'User 5',
      urlAvatar:
        'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-05.png',
      units: 60,
    },
    {
      reference: '6',
      position: 6,
      username: 'User 6',
      urlAvatar:
        'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-06.png',
      units: 50,
    },
    {
      reference: '7',
      position: 7,
      username: 'User 7',
      urlAvatar:
        'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-07.png',
      units: 40,
    },
    {
      reference: '8',
      position: 8,
      username: 'User 8',
      urlAvatar:
        'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-08.png',
      units: 30,
    },
    {
      reference: '9',
      position: 9,
      username: 'User 9',
      urlAvatar:
        'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-09.png',
      units: 20,
    },
    {
      reference: '10',
      position: 10,
      username: 'User 10',
      urlAvatar:
        'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-10.png',
      units: 10,
    },
  ];
};

export const mockChallengeLeaderboard =
  (): NewChallenge['challengeLeaderboard'] => {
    return {
      actors: mockActors(),
    };
  };

export const mockChallenge = (
  goalStaticReference?: ChallengeGoalStaticReference,
  audienceStaticReference = ChallengeAudienceStaticReference.INDIVIDUAL,
  modeStaticReference = ChallengeModeStaticReference.PLAYER_VS_PLAYER,
  typeStaticReference = ChallengeTypeStaticReference.CLASSIC,
  statusStaticReference = StatusStaticReference.NOT_STARTED,
  hasFinished = false,
): NewChallenge => {
  const currentTime: number = new Date().getTime();

  const endDate: number =
    currentTime +
    [DatePeriod.HOUR * 0.1, DatePeriod.HOUR * 0.2, DatePeriod.HOUR * 0.3][
      getRandomNumber(2)
    ];

  const startDate = (): number => {
    if (hasFinished) {
      return endDate;
    }
    if (statusStaticReference === StatusStaticReference.ON_GOING) {
      return currentTime - DatePeriod.HOUR;
    }
    return currentTime + DatePeriod.HOUR;
  };

  const targetAmount =
    goalStaticReference !== ChallengeGoalStaticReference.LEAST_POINTS_WIN &&
    goalStaticReference !== ChallengeGoalStaticReference.MOST_POINTS_WIN
      ? 100
      : undefined;

  const coinsPerUnit =
    typeStaticReference === ChallengeTypeStaticReference.ACHIEVER
      ? getRandomNumber(100)
      : undefined;

  return {
    challengeInfo: {
      description: 'test description',
      units: {
        current: 10,
        goal: targetAmount,
      },
      illustration: {
        default: false,
        description: 'test description',
        imageType: 'image/svg+xml',
        name: 'test illustration',
        organizationId: 1,
        reference: '1',
        url: 'https://api.develop.vaibe.app/v2/storages/container/org1/image/team_cooperation_reach_target_together.svg',
      },
      reference: 'asdsa',
      name: 'test challenge',
      unitMeasureName: 'unitMeasure.quantity',
      metric: 'Picking',
      potentialCoins: 100,
      coinsPerUnit,
      endDate,
      startTime: startDate(),
      type: {
        url: '',
        staticReference: typeStaticReference,
      },
      mode: {
        url: '',
        staticReference: modeStaticReference,
      },
      goal: goalStaticReference,
      status: {
        name: 'test status',
        staticReference: statusStaticReference,
      },
      audienceStaticReference,
      totalPoints: 30,
      goalStatement: 'statement',
    },
    challengeLeaderboard: mockChallengeLeaderboard(),
  };
};

const newChallengePossibleCombinations: [
  ChallengeGoalStaticReference | undefined,
  ChallengeAudienceStaticReference,
  ChallengeModeStaticReference,
  ChallengeTypeStaticReference,
  StatusStaticReference,
][] = [
  [
    ChallengeGoalStaticReference.LEAST_POINTS_WIN,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeModeStaticReference.PLAYER_VS_PLAYER,
    ChallengeTypeStaticReference.CLASSIC,
    StatusStaticReference.ON_GOING,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeModeStaticReference.PLAYER_VS_PLAYER,
    ChallengeTypeStaticReference.FASTEST,
    StatusStaticReference.COMPLETED,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeModeStaticReference.TOGETHER_AS_TEAM,
    ChallengeTypeStaticReference.REACHED_AS_TEAM,
    StatusStaticReference.COMPLETED,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.INDIVIDUAL,
    ChallengeModeStaticReference.SINGLE_PLAYER,
    ChallengeTypeStaticReference.ACHIEVER,
    StatusStaticReference.CALCULATING_RESULTS,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeModeStaticReference.TEAM_COOPERATION,
    ChallengeTypeStaticReference.REACHED_AS_TEAM,
    StatusStaticReference.CALCULATING_RESULTS,
  ],
  [
    ChallengeGoalStaticReference.MOST_POINTS_WIN,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeModeStaticReference.TEAM_DUEL,
    ChallengeTypeStaticReference.CLASSIC,
    StatusStaticReference.ON_GOING,
  ],
  [
    ChallengeGoalStaticReference.LEAST_POINTS_WIN,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeModeStaticReference.TEAM_DUEL,
    ChallengeTypeStaticReference.CLASSIC,
    StatusStaticReference.ON_GOING,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeModeStaticReference.TEAM_DUEL,
    ChallengeTypeStaticReference.FASTEST,
    StatusStaticReference.ON_GOING,
  ],
  [
    undefined,
    ChallengeAudienceStaticReference.TEAM,
    ChallengeModeStaticReference.TEAM_COOPERATION,
    ChallengeTypeStaticReference.REACHED_AS_TEAM,
    StatusStaticReference.ON_GOING,
  ],
];

export const allChallenges: NewChallenge[] =
  newChallengePossibleCombinations.map((parameters) => {
    return mockChallenge(...parameters);
  });

export const challengesResponse = (): NewChallengeSlide => {
  return {
    challenges: allChallenges,
    rotationDuration: 10000,
    finishChallengesPeriod: 72000,
  };
};
