import {
  AchievementName,
  AchievementsResponse,
  AchievementType,
  Achievement,
} from '@/shared/types/achievements';

const iconAchievements = {
  topShoppers:
    'https://api.develop.vaibe.app/v2/storages/container/org1/image/top_shoppers.svg',
  topAchievers:
    'https://api.develop.vaibe.app/v2/storages/container/org1/image/top_achievers.svg',
  kudosReceived:
    'https://api.develop.vaibe.app/v2/storages/container/org1/image/kudos_receivers.svg',
  kudosGiven:
    'https://api.develop.vaibe.app/v2/storages/container/org1/image/kudos_givers.svg',
};

export const createAchievement = (
  kpi = AchievementType.KUDOS_RECEIVED,
  label = AchievementName.KUDOS_RECEIVED,
  icon = iconAchievements.kudosReceived,
): Achievement => ({
  firstPlace: {
    username: 'John Doe',
    value: 100,
    avatar:
      'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-01.png',
  },
  secondPlace: {
    username: 'Jane Doe',
    value: 90,
    avatar:
      'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-02.png',
  },
  thirdPlace: {
    username: 'Jack Doe',
    value: 80,
    avatar:
      'https://api.develop.vaibe.app/v2/storages/container/org1/image/avatar-cartoon-03.png',
  },
  kpi,
  reference: '1',
  label,
  noData: false,
  order: 0,
  icon,
});

export const mockAchievements: Achievement[] = [
  createAchievement(),
  createAchievement(
    AchievementType.KUDOS_GIVEN,
    AchievementName.KUDOS_GIVEN,
    iconAchievements.kudosGiven,
  ),
  createAchievement(
    AchievementType.ACHIEVEMENTS_UNLOCKED,
    AchievementName.ACHIEVEMENTS_UNLOCKED,
    iconAchievements.topAchievers,
  ),
  createAchievement(
    AchievementType.STORE_PURCHASE,
    AchievementName.STORE_PURCHASE,
    iconAchievements.topShoppers,
  ),
];

export const getMockAchievementsResponse = (): AchievementsResponse => ({
  metadata: {
    totalItems: 4,
    lastUpdated: 1735554681820,
  },
  results: mockAchievements,
});
