import { computed, reactive } from 'vue';
import { defineStore } from 'pinia';
import { OrganizationDisplay } from '@/shared/types/organization';
import organizationService from '@/shared/services/organization/organizationService';
import { Locale } from '@/shared/types/generic';

interface State {
  organizationName: string;
  organizationDisplays: OrganizationDisplay[];
  selectedDisplay?: OrganizationDisplay;
  language?: Locale;
  keepDisplayCode: number;
}

export default defineStore('organization', () => {
  const state = reactive<State>({
    organizationName: '',
    organizationDisplays: [],
    selectedDisplay: undefined,
    keepDisplayCode: 0,
  });

  const orgNameComputed = computed(
    () => state.selectedDisplay?.orgName || state.organizationName,
  );

  const onlyChallengesWidgetEnabled = computed(
    () =>
      state.selectedDisplay?.challenges?.enabled &&
      !state.selectedDisplay?.metrics?.enabled,
  );

  const onlyMetricsWidgetsEnabled = computed(
    () =>
      state.selectedDisplay?.metrics?.enabled &&
      !state.selectedDisplay?.challenges?.enabled,
  );

  const challengesWidgetEnabled = computed(
    () => state.selectedDisplay?.challenges?.enabled,
  );

  const loadOrganizationDisplays = async (orgName: string): Promise<void> => {
    try {
      const response = await organizationService.getOrganizationDisplays(
        orgName,
      );

      state.organizationDisplays = response.displays;

      state.language = response.language;
    } catch (e) {
      state.organizationDisplays = [];
    }
  };

  const selectDisplay = (display: OrganizationDisplay) => {
    state.selectedDisplay = display;
  };

  const loadOrganizationDisplayByDisplayCode = async (displayCode: number) => {
    try {
      state.keepDisplayCode = displayCode;
      state.selectedDisplay =
        await organizationService.getOrganizationDisplayByDisplayCode(
          displayCode,
        );
    } catch (e) {
      state.selectedDisplay = undefined;
    }
  };

  const hasOnlyOneDisplay = computed(
    () => state.organizationDisplays.length === 1,
  );

  const $reset = () => {
    state.organizationName = '';
    state.organizationDisplays = [];
    state.selectedDisplay = undefined;
  };

  return {
    state,
    loadOrganizationDisplays,
    loadOrganizationDisplayByDisplayCode,
    selectDisplay,
    hasOnlyOneDisplay,
    orgNameComputed,
    $reset,
    onlyChallengesWidgetEnabled,
    onlyMetricsWidgetsEnabled,
    challengesWidgetEnabled,
  };
});
